.feature-cardOne-adjustments {
  .card-tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;

    li {


      min-width: 104px;

      @include mobile {
        min-width: calc(33.3333% - 12px);
      }

      @include desktops {
        min-width: 104px;
      }

      @include large-desktops {
        min-width: calc(33.3333% - 12px);

      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        margin-bottom: 10px;
        margin-left: 6px;
        margin-right: 6px;
        height: 32px;

      }
    }
  }

  .card-btn-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;

    @include large-desktops {}

    .btn {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 10px;
    }
  }
}