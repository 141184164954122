.select2 {
    .selection {
        width: 100%;
    }

    .select2-selection {
        min-height: $input-height;
        font-family: $input-font-family;
        @include font-size($input-font-size);
        font-weight: $input-font-weight;
        border: $input-border-width solid $input-border-color;
        color: $input-color;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        @include border-radius($input-border-radius, 0);
    }

    .select2-selection__rendered {
        margin: 0;
    }

    .select2-search__field {
        color: $input-color;
    }
}

.select2-dropdown {
    border-color: $input-border-color;
    @include border-bottom-radius($input-border-radius);
}

.select2-container--default {
    &.select2-container--focus {
        .select2-selection--multiple {
            border: $input-border-width solid $input-border-color;
        }
    }

    .select2-results__option--selected {
        background-color: $gray-200;
    }

    .select2-results__option--highlighted {
        &.select2-results__option--selectable {
            background-color: $primary;
        }
    }
}

.select2-search {
    &.select2-search--inline {
        .select2-search__field {
            margin: 0;
            padding-left: .5rem;
        }
    }
}