.pagination {
    justify-content: center;
}

.page-item {
    &.active {
        background: $primary;
        border-radius: $input-border-radius;
        .page-link {
            color: $white;
        }
    }

    &.disabled .page-link {
        background: none;
    }
}

.page-link {
    background: none;
    height: 28px;
    width: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}