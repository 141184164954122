#contact-ad {
    .modal-dialog {
        @include media-breakpoint-up(md) {
            width: 600px;
            max-width: 600px;
        }
    }
}

.ad-card__title {
    line-height: 1.1;
}
