@font-face {
    font-family: 'ProximaNova';
    src: url('../../fonts/proxima-nova/proximanova-light-webfont.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('../../fonts/proxima-nova/proximanova-semibold-webfont.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('../../fonts/proxima-nova/proximanova-bold-webfont.woff') format('woff');
    font-weight: 700;
}

