.media-uploader__wrapper {
    background-size: cover;
    flex-direction: column;
    text-align: center;
    color: #37474F;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: 2px dotted;
    position: relative;
    cursor: pointer;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $light;
    }

    label {
        cursor: pointer;
    }

    &.media-uploader__wrapper--with-image {
        label {
            display: none;
        }
    }

    .media-uploader__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .media-uploader__icon {
        font-size: 2.5rem;
    }
}

.media-uploader.is-invalid {
    .media-uploader__wrapper {
        border-color: $danger;
    }
}