@import "components/fonts";

@import "theme/main";

@import "plugins/quill-custom";
@import "plugins/select2-custom";

@import "components/typo";
@import "components/form";
@import "components/media-uploader";
@import "components/pagination";
@import "components/social-icons";
@import "components/badge";
@import "components/dashboard";
@import "components/candidates";
@import "components/ads";
@import "components/toasts";

// Custom styles

// For sticky footer
body, html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.site-wrapper {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}
// End of sticky footer

.divider--80 {
    width: 80%;
}

.site-wrapper {
    position: relative;
}

.bg-gray {
    background-color: var(--bg-2) !important;
}

.bg-dark-gray {
    background-color: $dark-gray !important;
    @include dark-theme-mode;
}

.spinner {
    width: 100px;
}

.bg-pattern {
    background-color: $gray-100;
    background-image: url('../images/patterns/backgroundtexture.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.card-tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        margin-bottom: 10px;
        margin-left: 6px;
        margin-right: 6px;
        height: 32px;
    }
}

.latest-ads-item__title {
    overflow: hidden;
    white-space: nowrap;
    mask-image: linear-gradient(270deg, transparent, #000 20%);
    mask-repeat: no-repeat;
    mask-position: left top,right top;
}

.latest-ads-item__body {
    height: 125px;
}