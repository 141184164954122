@keyframes tick {
    0% {
        transform: scale(0)
    }
    90% {
        transform: scale(1.3)
    }
    100% {
        transform: scale(1)
    } 
}
  
.custom-file-label {
    height: 3.5rem;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 125px;

    span {
        display: block;
        max-width: 100%;
        overflow-x: hidden;
    }
}

.custom-file-input {
    height: 3.5rem;
}

.custom-file-remove-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 91px;
    margin: auto;
    z-index: 10;
    border: 0;
    background: none;
    padding: 0;
    opacity: .6;

    &:hover, &:focus {
        opacity: .8;
    }

    img {
        width: 25px;
        height: 25px;
    }
}

.input-group > .custom-file {
    height: auto;
}

.nice-select .list {
    max-height: 300px;
    overflow-y: scroll;
}
 
input[type="checkbox"] + .label-text:hover:before {
    color: $primary;
}

.nice-checkbox {
    cursor: pointer;

    input[type="checkbox"] {
        display: none;

        + .label-text {
            display: flex;
            align-items: center;
            @include font-size($text-base);
        }
    
        + .label-text:before {
            content: "\f0c8";
            font-weight: 900;
            color: $gray-500;
            font-family: "Font Awesome 5 Free";
            font-size: 1.2rem;
            display: inline-block;
            margin-right: 0.7rem;
        }
        
        &:checked + .label-text:before {
            content: "\f14a";
            color: $primary;
            animation: tick 150ms ease-in;
        }
        
        &:disabled + .label-text:before {
            content: "\f0c8";
            color: $gray-300;
        }
    }
}

.nice-radio {
    cursor: pointer;

    input[type="radio"] {
        display: none;

        + .label-text {
            display: flex;
            align-items: center;
            @include font-size($text-base);
        }
    
        + .label-text:before {
            content: "\f111";
            font-weight: 900;
            color: $gray-500;
            font-family: "Font Awesome 5 Free";
            font-size: 1.2rem;
            display: inline-block;
            margin-right: 0.7rem;
        }
        
        &:checked + .label-text:before {
            content: "\f192";
            color: $primary;
            animation: tick 150ms ease-in;
        }
        
        &:disabled + .label-text:before {
            content: "\f111";
            color: $gray-300;
        }
    }
}

.form-group {

    &.double-margin {
        margin-bottom: 2.5rem;
    }

    &.is-invalid {
        .form-control {
            border-color: $form-feedback-invalid-color;
    
            @if $enable-validation-icons {
                padding-right: $input-height-inner;
                background-image: escape-svg($form-feedback-icon-invalid);
                background-repeat: no-repeat;
                background-position: right $input-height-inner-quarter center;
                background-size: $input-height-inner-half $input-height-inner-half;
            }
    
            &:focus {
                border-color: $form-feedback-invalid-color;
                box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
            }
        }

        .invalid-feedback {
            display: block;
        }

        .ql-toolbar, .ql-container, .select2-selection {
            border-color: $danger;
        }
    }
}

.filter-check-list__item {
    display: flex;
    align-items: center;
}

.filter-check-list__input {
    margin-right: .7rem;
}

.filter-check-list__label {
    margin: 0;
}

.filter-search-form-2 .filter-inputs {
    @include media-breakpoint-up(lg) {
        width: 100%;
    }
}