#toast-container {
    > .toast {
        width: 330px;
        position: relative;
        padding: 15px 30px 15px 15px;
        border: none;
        overflow: visible;
        opacity: 1;
        box-shadow: 1px 1px 10px $gray-600;

        &:hover {
            box-shadow: 1px 1px 10px $gray-600;
        }
    
        &.toast-success {
            background-color: $success;
            color: $white;
            background-image: none !important;
        }
    
        &.toast-error {
            background-color: $danger;
            color: $white;
            background-image: none !important;
        }
    
        &.toast-info {
            background-color: $info;
            color: $white;
            background-image: none !important;
        }
    
        .toast-close-button {
            position: absolute;
            margin: 0;
            display: block;
            background-color: #fff;
            color: $body-color;
            opacity: 1;
            text-shadow: none;
            right: -10px;
            top: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
    
            &:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus {
                opacity: 1;
                background-color: darken($white, 15%);
            }
        }
    }
}