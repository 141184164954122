.badge-wrapper {
    display: inline-block;
}

.badge-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.badge-list-item {
    margin-bottom: .3rem;

    &:not(:last-of-type) {
        margin-right: .3rem;
    }
}

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 .8rem;
    height: 32px;
    font-size: 13px;
    line-height: 2;
    letter-spacing: 0.26px;
    border-radius: 3px;
    font-weight: 400;
}

.badge__icon {
    margin-right: 0.375rem;
}

.badge--regent {
    background-color: rgba(176, 213, 232, 0.15);
    color: #1a5aeb;
}

.badge--orange {
    background-color: rgba($orange, 0.10);
    color: #fa5f1c;
}

.badge--green {
    background-color: rgba($green, 0.10);
    color: darken($green, 10%);
}

.badge--simple {
    background-color: rgba(176, 213, 232, 0.15);
    color: #2b3940;
}