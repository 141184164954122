.dashboard-ads__button {
    color: $body-color;
    background: none;
    border: none;

    &:hover {
        color: $body-color;
        opacity: .7;
    }

    &.dashboard-ads__button--delete {
        color: $danger;

        &:hover {
            color: $danger;
        }
    }
}