.candidate-card {
    .candidate__name {
        line-height: 1rem;

        a {
            line-height: 1;
        }
    }
}

.candidate__presentation {
    h1 {
        font-size: 1.2rem;
    }
    h2 {
        font-size: 1.1rem;
    }
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: 1rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 1rem;
        color: $gray-600;
    }
}