.display-1 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 4rem;
}

.heading-1 {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 300;
}