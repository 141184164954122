.ql-editor {
    font-family: $font-family-base;
    
    p {
        font-size: $font-size-base;
        line-height: $input-line-height;
    }
    ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
        font-size: initial;
    }
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border: $input-border-width solid $input-border-color;
}

.ql-toolbar {
    @include border-top-radius($input-border-radius);
}

.ql-container {
    @include border-bottom-radius($input-border-radius);
}