.social-icon {
    a {
        color: $white;
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        background: $primary;
        border-radius: 50%;
        transition: opacity .1s ease-in;

        &:hover {
            opacity: .7;
        }
    }

    &.social-icon--facebook {
        a {
            background: #3b5998;
        }
    }

    &.social-icon--linkedin {
        a {
            background: #0e76a8;
        }
    }

    &.social-icon--twitter {
        a {
            background: #1DA1F2;
        }
    }

    &.social-icon--instagram {
        a {
            background: #C13584;
        }
    }
}

.social-icons-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.social-icons-list--inline {
    display: flex;

    .social-icons-list-item:not(:last-of-type) {
        margin-right: .2rem;
    }
}